import React from 'react';

import Container from 'react-bootstrap/Container';

import ProkoNavigation from "../components/nav";
import Footer from "../components/footer";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styled from "styled-components";
import {Button} from "react-bootstrap";

import Person from '../components/person';

export default () => {

    return (
        <>
            <ProkoNavigation showLogo />

            <div className={'mt-5'}>
                <img className={'w-100'} src={'/images/about-banner.png'} alt={''} />
            </div>

            <Container fluid>
              {/*<Banner text={'THE TEAM BEHIND THE NORTH’S LEADING INDEPENDENT PORSCHE SPECIALIST.'} />*/}


                <Row>
                    <Col className={'text-center pt-5 pb-5'}>
                        <h5>THE TEAM BEHIND THE NORTH’S LEADING INDEPENDENT PORSCHE SPECIALIST.</h5>
                        <p className={'mt-5 mr-auto ml-auto w-75'}>
                            Founded in 1994 by Dave Sunderland, Mark Pendleton and David Forrest, Strasse is now firmly established as one of the country’s leading independent Porsche specialists. Everyone at Strasse has a common goal and a passion for Porsche. The Strasse technicians and colleagues have all been bitten by love for Porsche – seeing these amazing pieces of engineering every day never fails to leave you in awe. Unrivalled knowledge and a combined Porsche experience of over 150 years  here at Strasse. So whatever your own Porsche passion is we like to think we can fuel it.  We know our Porsches, inside, outside and underneath!
                        </p>
                    </Col>
                </Row>

            </Container>


            <div className={'mt-0'}>
                <img className={'w-100'} src={'/images/us-banner.jpg'} alt={''} />
            </div>






            <Container fluid>
                <Row>
                    <Col className={'pt-5'} style={{background: '#FFF'}}>
                        {/*<Row>*/}
                        {/*    <Col className={'text-center pt-3 pb-3'}>*/}
                        {/*        <p className={'mr-auto ml-auto'}>*/}
                        {/*            Strasse began life as a service repair garage but has over years, been able to gently expand whilst retaining real friendliness and customer contact, we offer*/}
                        {/*            Pre-Owned Sales in a separate dedicated building, as well as service and maintenance work, engine and gearbox rebuilds, full body shop and restoration*/}
                        {/*            facilities. We have also become widely known and respected for the building of Porsche for race and rally and providing race support at circuits around the UK.*/}
                        {/*            Involvement with the Porsche Club of Great Britain and charity fund raising has earned David Forrest lifetime Honorary Membership of the PCGB as well as high*/}
                        {/*            recognition for this outstanding contribution to Motorsport. Strasse charity work has included local childrens charities as well as Alzheimer’s and local hospital*/}
                        {/*            research. What Strasse has never done however is grown too big - almost all our customers are known by first name -keeping it personal is what we are about.*/}
                        {/*        </p>*/}
                        {/*    </Col>*/}
                        {/*</Row>*/}

                        <Row>
                            <Person title={'Owner'} subtitle={'Founder'} founder image={'david.png'} name={'dave forrest'} description={'You must know df right? He has been eyeing Porsches up since he was a little lad - and along with Dave Sunderland was fully Porsche trained before he set up Strasse in 1994 never looking back – and never ever losing his Porsche passion – with motorsport and cars in the family blood his enthusiasm never wanes.'} className={''} />
                            <Person title={'Co-Owner'} subtitle={'Founder'} founder image={'lindsey.png'} name={'lindsay dobby'} description={'Lindsay has been a solid team member since the start handling the logistical side of Strasse and Strasse Motorsport, finances and the Yorkshire Porsche Festival every year, the go to girl  and all round counsellor – never gets to finish a cup of Archie’s tea'} className={''} />
                            <Person title={'Founder'} subtitle={''} founder image={'dave.png'} name={'Dave Sunderland'} description={'Difficult to describe the man the myth the legend (as he is known in the uk Porsche world) that is Dave Sunderland cutting his teeth on Porsche in 1968 he has never let up. Mentor and teacher to our apprentices and still putting together the sweetest air cooled engines and gearbox you will find .'} className={''} />
                            <Person title={'Management'} subtitle={''} date={'2016'} image={'sam.png'} name={'sam Forrest'} description={'Sam is the next generation for Strasse taking us forward to the future he loves his Porsches (having been brought up surrounded by them it would be hard not to appreciate them) Sam has fitted into the team like a hand in glove – managing the workshop alongside Chris Dyer dealing with Porsche car sales – Sam is a people person.'} className={''} />
                            <Person title={'Porsche'} subtitle={'Technician'} date={'2000'} image={'chris.png'} name={'chris Dyer'} description={'Chances are if you have a Strasse built water cooled engine it will have Chris’ name across it – same applies with our race winning motorsport engines – known by our race customers as the wizard'} className={''} />
                            <Person title={'Porsche'} subtitle={'Technician'} date={'2005'} image={'g.png'} name={'dave Goddard'} description={'Dave is valued team member and senior tech he is one of the three Daves that may be in the building at any one time. Extensive product knowledge and never defeated. A problem solver – highly experienced '} className={''} />
                            <Person title={'Porsche'} subtitle={'Technician'} date={'2015'} image={'luke.png'} name={'luke Howarth'} description={'Apprenticed originally to Chris Dyer is now a fully fledged Porsche tech and motorsport race mechanic – Luke has perfect hair and loves his Porsches – he also apprenticed with the legend so has a great all round knowledge.'} className={''} />
                            <Person title={'Apprentice'} subtitle={'Technician'} date={'2021'} image={'archie.png'} name={'Archie Lambert'} description={'Joined as an apprentice now fully qualified and learning daily at the hands of the highly experienced Strasse team. Moulded into the team perfectly, professional willing to learn and makes good tea.'} className={''} />
                        </Row>

                    </Col>
                    <Col style={{background: '#E2E0DF'}} xs={3} className={'d-none d-lg-block pb-5'}>
                        <p className={'pt-3'}>Contact Strasse.</p>

                        <p className={'pt-3'}>Opening hours Mon- Fri 8.00 – 5.00 Sat 8.30 – 12.30 all other times by appointment only. </p>

                        <List className={'pt-3 pb-0'}>
                            <li>Unit 911</li>
                            <li>Bridge Road</li>
                            <li>Holbeck</li>
                            <li>Leeds</li>
                            <li>LS11 9UG</li>
                            <li>West Yorkshire</li>
                            <li>&nbsp;</li>
                            <li>Tel: 0113 2340 911</li>
                            <li>Email: info@strasse.co.uk</li>
                        </List>

                        <p className={'pt-3'}>
                        For our latest news and to see live projects
                        please follow our social media pages on
                        </p>

                        {/*<Link to={'/cars/porsche'}>*/}
                        <EmailButton className={'mt-3'} href={"mailto:info@strasse.co.uk"}>Email us</EmailButton>
                        {/*</Link>*/}
                    </Col>
                </Row>
            </Container>


            <div className={'mt-5'}>
                <img className={'w-100'} src={'/images/contact-banner.png'} alt={''} />
            </div>

            <Container fluid>
                <Row>
                    <Col className={'pt-3'} style={{background: '#FFF'}}>

                        <Row>
                            <Col xs={6} className={`pb-3`}>
                                <img className={'img-fluid'} src={'/images/people/about-image-1.png'} alt='' />
                            </Col>
                            <Col xs={6} className={`pb-3`}>
                                <img className={'img-fluid'} src={'/images/people/about-image-2.png'} alt='' />
                            </Col>
                            <Col xs={6} className={`pb-3`}>
                                <img className={'img-fluid'} src={'/images/people/about-image-3.png'} alt='' />
                            </Col>
                            <Col xs={6} className={`pb-3`}>
                                <img className={'img-fluid'} src={'/images/people/about-image-4.png'} alt='' />
                            </Col>
                            <Col xs={6} className={`pb-3`}>
                                <img className={'img-fluid'} src={'/images/people/about-image-5.png'} alt='' />
                            </Col>
                            <Col xs={6} className={`pb-3`}>
                                <img className={'img-fluid'} src={'/images/people/about-image-6.png'} alt='' />
                            </Col>
                            <Col xs={6} className={`pb-3`}>
                                <img className={'img-fluid'} src={'/images/people/about-image-7.png'} alt='' />
                            </Col>
                            <Col xs={6} className={`pb-3`}>
                                <img className={'img-fluid'} src={'/images/people/about-image-8.png'} alt='' />
                            </Col>
                            <Col xs={6} className={`pb-3`}>
                                <img className={'img-fluid'} src={'/images/people/about-image-9.png'} alt='' />
                            </Col>
                            <Col xs={6} className={`pb-3`}>
                                <img className={'img-fluid'} src={'/images/people/about-image-10.png'} alt='' />
                            </Col>
                        </Row>

                    </Col>
                    <Col style={{justifyContent: 'flex-end', background: '#E2E0DF', flexDirection: 'column'}} xs={3} className={'d-none d-lg-flex pb-5'}>
                        {/*<p className={'pt-3'}>Contact Strasse.</p>*/}

                        {/*<p className={'pt-3'}>We are open Monday to Saturday - and on Sunday by appointment.</p>*/}

                        {/*<List className={'pt-3 pb-0'}>*/}
                        {/*    <li>Unit 911</li>*/}
                        {/*    <li>Bridge Road</li>*/}
                        {/*    <li>Holbeck</li>*/}
                        {/*    <li>Leeds</li>*/}
                        {/*    <li>LS11 9UG</li>*/}
                        {/*    <li>West Yorkshire</li>*/}
                        {/*    <li>&nbsp;</li>*/}
                        {/*    <li>Tel: 0113 2340 911</li>*/}
                        {/*    <li>Email: info@strasse.co.uk</li>*/}
                        {/*</List>*/}

                        {/*<p className={'pt-3'}>*/}
                        {/*    For our latest news and to see live projects*/}
                        {/*    please follow our social media pages on*/}
                        {/*</p>*/}

                        {/*/!*<Link to={'/cars/porsche'}>*!/*/}
                        <h5>CALL US ABOUT YOUR CAR
                        <br />0113 2340911</h5>
                        <EmailButton className={'mt-3'} href={"mailto:info@strasse.co.uk"}>Email us</EmailButton>
                        {/*</Link>*/}
                    </Col>
                </Row>
            </Container>

            <Footer />

        </>
    );
}

const EmailButton = styled.a`
text-decoration: none;
    background-color: #E2E0DF;
    color: #090808;
    border-radius: 200px;
    border: 1px solid #090808;
    padding: 0 1rem;
`;

const List = styled.ul`
  padding: 0;
  list-style: none;
`;