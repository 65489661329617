import React from 'react';

import Container from 'react-bootstrap/Container';

import ProkoNavigation from "../components/nav";
import Footer from "../components/footer";
import CarListings from "../components/car-listings";
import Banner from "../components/banner";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CarsFilter from "../components/car-listings/filter";
import Car from "../components/car-listings/car";
import styled from "styled-components";
import {Button} from "react-bootstrap";

import Person from '../components/person';

export default () => {

    return (
        <>
            <ProkoNavigation showLogo />

            <div className={'mt-5'} style={{position: 'relative'}}>
            <img style={{position: 'absolute', width: '300px', top: '50%', left: '3rem'}} src={'/images/Strasse-motorsport_type.png'} alt={''} />
                <img className={'w-100'} src={'/images/motorsport-banner.png'} alt={''} />
            </div>

            <Container fluid>


                <Row style={{background: 'rgb(226, 224, 223)'}}>
                    <Col className={'text-center pt-5 pb-5'}>
                        <h5>Strasse Motorsport</h5>
                        <p className={'mt-3 mr-auto ml-auto w-75'}>
                        In 2016 Dave Forrest was awarded from the PCGB the Bill Goodman Trophy having been judged as someone having an exceptional record of long and excellent service to the Club, he was further awarded life time Hon Membership of the PCGB in 2017 for his long and tireless promotion and support of the Club and in particular its Motorsport. In 2017 Strasse technician and race competitor Chris Dyer was awarded the Bill Taylor Trophy.
                        </p>

<p className='pl-5 pr-5'>
The list of Team and Individual Championships won by Strasse are to numerous to mention but we have been consistent winners with very reliable cars for our customers over more than 2 decades. 
    </p>
                    </Col>
                </Row>


            </Container>

            <Container fluid>
                <Row className='pt-4'>
                    <Col xs={12} md={6}>
                    <img style={{width: '100%'}} src='/images/ms-1.png' alt='' />
                    </Col>
                    <Col xs={12} md={6}>
                    <img style={{width: '100%'}} src='/images/ms-2.png' alt='' />
</Col>
                    </Row>

                    <Row className='pt-4 pb-4'>
                    <Col xs={12} md={8}>
                    <img style={{width: '100%'}} src='/images/ms-3.png' alt='' />
                    </Col>
                    <Col xs={12} md={4}>
                    <img style={{width: '100%'}} src='/images/ms-4.png' alt='' />
</Col>
                    </Row>
                    </Container>


            <img style={{width: '100%'}} src='/images/motorsport-image-strip.png' alt='' />

            <Container fluid>
                <Row>
                    <Col className={'pt-0'} style={{background: '#FFF'}}>

                        <Row>
                            <Col className={'pb-3'}>
                                <div className="youtube">
                                    <iframe src="//www.youtube.com/embed/fc1xHMuLLIo" frameBorder="0" className="video"></iframe>
                                </div>
                            </Col>
                        </Row>

                    </Col>
                    <Col style={{justifyContent: 'flex-start', flexDirection: 'column'}} xs={3} className={'d-none d-lg-flex pb-5'}>
                        <p className={'pt-3'}>Strasse Motorsport</p>

                        <p className={'pt-3'}>
                            Please use the links below to see our latest
                            race results.
                            </p>
                        <p className={'mb-5'}>
                            To see live motorsport results please follow
                            our social media pages on Facebook and
                            Twitter.
                        </p>

                        <EmailButton href={'https://www.facebook.com/StrassePorsche/'}>Facebook</EmailButton>
                        <EmailButton href={'https://www.instagram.com/strasseporsche/'}>Instagram</EmailButton>
                        <EmailButton href={'https://twitter.com/strasseleeds'}>Twitter</EmailButton>
                        <EmailButton href={'https://www.youtube.com/channel/UCUtHLVFU3MQGSInpC4z0X8w'}>YouTube</EmailButton>
                    </Col>
                </Row>
            </Container>

            <Container fluid className='p-0' style={{background: '#ec0e22'}}>

                {/*<Row>*/}
                {/*    <Col>*/}
                {/*        <img className='w-100' src='/images/brake-advert.png' alt='' />*/}
                {/*    </Col>*/}
                {/*</Row>*/}

                <Row className='p-4 pb-0'>
                    <Col>
                        <img className='w-50' src='/images/Strasse-logo.png' alt='' />
                    </Col>
                    <Col>
                    </Col>
                </Row>
                <Row className='pl-4 pr-4 pb-4'>
                    <Col md={6} xs={12}>
                        <p style={{color: '#fff'}} className='mt-0 mb-0'>Official suppliers of</p>
                        <h2 style={{color: '#fff'}}>CARBON CERAMIC DISCS.</h2>
                        <EmailButton href={"mailto:info@strasse.co.uk?subject=Ceramic breaks&body=Hi, Im interested in the Ceramic breaks."}>Available for 991-992</EmailButton>
                    </Col>
                    <Col >
                        <img className='w-50' src='/images/surface-logo.png' alt='' />
                    </Col>
                </Row>

                <Row className='p-4'>
                    <Col>
                        <img className='w-100' src='/images/brake-image.png' alt='' />
                    </Col>
                </Row>

                <Row className='p-4'>
                    <Col md={6} xs={12}>
                        <h4 style={{color: '#fff'}}>Surface Transforms has developed unique patented next-generation Carbon-Ceramic Technology that provides the ultimate braking performance for road and track. Here’s seven reasons why you need this technology on your vehicle:</h4>
                    </Col>
                    <Col>
                        <ul style={{color: '#fff', fontSize: '14px'}}>
                            <li>Weight savings of up to 70% compared to iron brakes (typically 20kg of unsprung weight) </li>
                            <li>Improved handling and driveability</li>
                            <li>Improved NVH (less noise, vibration and harshness)</li>
                            <li>Improved performance (in both wet and dry conditions)</li>
                            <li>Reduced brake wear – giving increased life</li>
                            <li>Corrosion Free</li>
                            <li>Outstanding performance, even from cold</li>
                        </ul>
                        <EmailButton href={"mailto:info@strasse.co.uk?subject=Ceramic breaks&body=Hi, Im interested in the Ceramic breaks."}>Enquire for your vehicle now</EmailButton>
                    </Col>
                </Row>

            </Container>


            <Footer />

        </>
    );
}

const EmailButton = styled.a`
text-decoration: none;
    background-color: #E2E0DF;
    color: #090808;
    border-radius: 200px;
    border: 1px solid #090808;
    padding: 0 1rem;
    margin-bottom: 1rem;
    width: 50%;
`;

const List = styled.ul`
  padding: 0;
  list-style: none;
  font-size: .7rem;
`;