import React, {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import FsLightbox from 'fslightbox-react';

import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import ProkoNavigation from "../components/nav";
import Footer from "../components/footer";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import * as contentful from 'contentful';

import styled from "styled-components";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';


var client = contentful.createClient({
    space: 'de8somyyf2bq',
    accessToken: '43yRDEal8Jm1ePvYf8YqAmR69l-FIZBue38IviAb26Q'
})

export default () => {
    const params = useParams();
    const [selectedImage, setSelectedImage] = useState(0);
    const [toggler, setToggler] = useState(false);
    const [car, setCar] = useState({});

    useEffect(async () => {
        const p = await client.getEntry(params.car);
        setCar(p.fields);
    }, [Object.keys(car).length])

    if(!car.make) {
        return null;
    }
   

    const {images, make, model, mileage, year, transmission, description, price} = car

    return (
        <>
            <ProkoNavigation showLogo />


            <Container fluid className={'mt-5'}>
                <Row style={{background: '#090808'}} className={'pt-5'}>
                    <Col>
                        <h1 className={'red'}>{make}{' '}{model}</h1>
                    </Col>
                </Row>

            </Container>


            <Container fluid className={'p-0'}>
                <Row>
                <Col xs={12} md={3} className={'p-0'}>
                    <Row>
                        {images.map((i, index) => (
                            <Col xs={4} className={'p-0'}>
                                <img style={{opacity: selectedImage === index ? 1 : 0.7}} className={'w-100'} onClick={() => setSelectedImage(index)} src={images[index].fields.file.url} alt={''} />
                            </Col>
                        ))}
                    </Row>
                </Col>
                <Col className={''} style={{maxHeight: '368px', overflow: 'hidden', display: 'flex'}}>
                    <img onClick={() => setToggler(!toggler)} className={'h-100 d-inline'} src={images[selectedImage].fields.file.url} alt={''} />

                    {!!(selectedImage !== (images.length - 1)) && (
                        <img onClick={() => setToggler(!toggler)} className={'h-100 d-inline'} src={images[selectedImage + 1].fields.file.url} alt={''} />
                    )}

                    {!!(selectedImage === (images.length - 1)) && (
                        <img onClick={() => setToggler(!toggler)} className={'h-100 d-inline'} src={images[0].fields.file.url} alt={''} />
                    )}

                    <FsLightbox
                        toggler={toggler}
                        sources={images.map(i => i.fields.file.url)}
                        slide={selectedImage + 1}
                    />
                </Col>
                </Row>
            </Container>

            <Container fluid>
                <Row className={''} style={{background: '#090808'}}>
                    <Col className={'d-flex text-white'} style={{justifyContent: 'flex-end'}}>
                        {selectedImage + 1} of {images.length}
                        <ChevronLeftIcon onClick={() => {
                            if(selectedImage !== 0) {
                                setSelectedImage(selectedImage - 1)
                            }
                        }} fontSize={'default'} className={'text-black'} />
                        <ChevronRightIcon onClick={() => {
                            if(selectedImage !== (images.length - 1)) {
                                setSelectedImage(selectedImage + 1)
                            }
                        }}fontSize={'default'} className={'text-white'} />
                    </Col>
                </Row>

                <Row>
                    <Col className={'pt-3'} style={{background: '#FFF'}}>
                        <Row className={'pt-0'}>
                            <Col xs={12} md={3}>
                                <EmailButton href={"mailto:info@strasse.co.uk"}>Quick info</EmailButton>
                                <div style={{display: 'flex'}}>


                                    <List className={'mt-3 pr-5'}>
                                        <li>
                                            <h5>Registration</h5>
                                        </li>
                                        <li>
                                            <h5>Gearbox</h5>
                                        </li>
                                        <li>
                                            <h5>Mileage</h5>
                                        </li>

                                        <li>
                                            <h5>Price</h5>
                                        </li>

                                    </List>
                                <List className={'mt-3 pr-5'}>
                                    <li>
                                        <p>{year}</p>
                                    </li>
                                   <li>
                                       <p>{transmission}</p>
                                   </li>
                                    <li>
                                        <p>{mileage}</p>
                                    </li>
                                    {/*<li>*/}
                                    {/*    <p>3.6</p>*/}
                                    {/*</li>*/}
                                    {/*<li>*/}
                                    {/*    <p>Coupe</p>*/}
                                    {/*</li>*/}
                                    {/*<li>*/}
                                    {/*    <p>Petrol</p>*/}
                                    {/*</li>*/}
                                    {/*<li>*/}
                                    {/*    <p>5</p>*/}
                                    {/*</li>*/}
                                    <li>
                                        <p>{price}</p>
                                    </li>

                                </List>
                                </div>
                            </Col>
                            <Col>
                                <h2 style={{display: 'inline-block'}} className={'mr-5 red'}>{price}</h2>
                                {/*<RedButton href={"mailto:info@strasse.co.uk?subject=Enquire about the Porsche 911 Turbo&body=Hi, Im interested in the Porsche you have for sale."} className={'d-inline-block mr-3'}>Reserve</RedButton>*/}
                                {/*<RedButton href={"mailto:info@strasse.co.uk?subject=Enquire about the Porsche 911 Turbo&body=Hi, Im interested in the Porsche you have for sale."} className={'mr-0 mb-3'}>Enquire</RedButton>*/}
<br/>
                                <EmailButton href={"mailto:info@strasse.co.uk"}>Description</EmailButton>
                                <div style={{fontSize: '.7rem', lineHeight: '1rem'}} className={'mt-3 mb-5'} dangerouslySetInnerHTML={{__html: documentToHtmlString(description)}}></div>


                                <EnquireButton href={"mailto:info@strasse.co.uk?subject=Enquire about the Porsche 911 Turbo&body=Hi, Im interested in the Porsche you have for sale."} className={'mt-0 mb-5'}>Enquire</EnquireButton>


                            </Col>

                            <Col xs={1}></Col>
                        </Row>

                    </Col>
                </Row>
            </Container>


            <Footer />

        </>
    );
}

const EmailButton = styled.div`
    background-color: transparent;
    color: #090808;
    border-radius: 200px;
    border: 1px solid #090808;
    padding: 0.15rem 1rem 0;
        display: inline-block;
`;

const EnquireButton = styled.a`
text-decoration: none;
    background-color: transparent;
    color: #090808;
    border-radius: 200px;
    border: 1px solid #090808;
    padding: 0.15rem 1rem 0;
        display: inline-block;
        text-decoration: none !important;
        cursor: pointer;
        
        &:hover{
        color: #090808;
        }
`;

const RedButton = styled.a`
text-decoration: none;
    background-color: #090808;
    color: #EB212D;
    border-radius: 200px;
    border: 1px solid #EB212D;
    padding: 0.15rem 1rem 0;
    
    
            display: inline-block;
        text-decoration: none !important;
        cursor: pointer;
        
        &:hover{
        color: #EB212D;
        }
`;

const List = styled.ul`
padding: 0;
list-style: none;

li {
        height: 15px !important;
        display: flex;
        justify-content: space-between;
        align-items: baseline;
    }

h5 {
        margin: 0;
        font-size: .7rem;
        font-weight: bold;
    }

p {
        padding: 0;
        font-size: .7rem !important;
    }
`;