import React from 'react';

import ProkoNavigation from "../components/nav";
import Footer from "../components/footer";
import CarListings from "../components/car-listings";
import Container from "react-bootstrap/Container";
import Banner from "../components/banner";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styled from "styled-components";
import ProkoHorizontalIconList from "../components/horizontal-icon-list";
import ProkoImageLinks from "../components/image-links";
import Card from "react-bootstrap/Card";
import { Button } from "react-bootstrap";
 
export default () => {

    return (
        <>
            <ProkoNavigation showLogo />

            <div className={'mt-5 pt-4'} style={{position: 'relative'}}>
                <img style={{position: 'absolute', width: '300px', top: '50%', left: '3rem'}} src={'/images/services.png'} alt={''} />
                <img className={'w-100'} src={'/images/services_header.png'} alt={''} />
            </div>
            <Container fluid>
                <Row>
                    <Col className={'text-center pt-5 pb-5'}>
                        <h5 className={'mb-3'}>THE TEAM BEHIND THE NORTH’S LEADING INDEPENDENT PORSCHE SPECIALIST.</h5>

                        <p className={'mr-auto ml-auto w-75 text-center'}>
                        Strasse - Servicing for Porsches - Leeds<br />
                            <small>Your car can find its own Porsche haven where it will be cared for and pampered.<br/>
We guarantee a personal service, with everyone fully involved on the workshop floor, and with tried, trusted and experienced staff.</small>
                        </p>
                    </Col>
                </Row>

            </Container>


            {/*<Container fluid>*/}

            {/*    <Row>*/}
            {/*        <Col xs={12} lg={4} className={'p-0'}>*/}


            {/*            <Card className="text-white rounded-0 border-0 hover-zoom">*/}
            {/*                <Card.Img className={'rounded-0'} src="/images/image-link-4.png" alt="Card image" />*/}
            {/*                <Card.ImgOverlay className={'justify-content-center align-items-center d-flex rounded-0 text-center'}>*/}
            {/*                    <Card.Title>PORSCHE SERVICING</Card.Title>*/}
            {/*                </Card.ImgOverlay>*/}
            {/*            </Card>*/}


            {/*        </Col>*/}
            {/*        <Col xs={12} lg={4} className={'p-0'}>*/}


            {/*            <Card className="text-white rounded-0 border-0 hover-zoom">*/}
            {/*                <Card.Img className={'rounded-0'} src="/images/image-link-4.png" alt="Card image" />*/}
            {/*                <Card.ImgOverlay className={'justify-content-center align-items-center d-flex rounded-0 text-center'}>*/}
            {/*                    <Card.Title className={''}>PARTS</Card.Title>*/}
            {/*                </Card.ImgOverlay>*/}
            {/*            </Card>*/}


            {/*        </Col>*/}
            {/*        <Col xs={12} lg={4} className={'p-0'}>*/}


            {/*            <Card className="text-white rounded-0 border-0 hover-zoom">*/}
            {/*                <Card.Img className={'rounded-0'} src="/images/image-link-4.png" alt="Card image" />*/}
            {/*                <Card.ImgOverlay className={'justify-content-center align-items-center d-flex rounded-0 text-center'}>*/}
            {/*                    <Card.Title>SERVICE BASED ON TRUST</Card.Title>*/}
            {/*                </Card.ImgOverlay>*/}
            {/*            </Card>*/}


            {/*        </Col>*/}
            {/*    </Row>*/}

            {/*</Container>*/}

            <ProkoHorizontalIconList />



            <Container fluid className='services-page'>
                <Row>
                    <Col className={'pt-5 pb-5'} style={{background: '#FFF'}}>
                        <Row>
                            <Col  md={7} xs={12}>
                                <img className={'w-100'}  src={'/images/race-build.jpg'} />
                            </Col>
                            <Col>
                            <aside className='services-page-content'>
                            <h5 className='mont-bold'>Servicing</h5>
                                <p>
                                Keeping your Porsche correctly serviced is not only important from a maintenance point of view but also comes into play when re-selling your car. Buyers like to see a good comprehensive history from a respected source.</p>

                                <p>
                                The team we have here is vastly experienced and both with service and maintenance, our service stamp in a guarantee and maintenance book is always looked upon very favourably by those in the Porsche world. </p>

                                    <p>Our technicians have been in the Porsche environment for decades and some have had main dealer experience. We use Millers Oils lubricants, which are fully Porsche approved and we have found them to be exceptional when used in our high performance race cars. We ensure only the highest quality of parts are used. We run the latest diagnostic systems, for the modern cars and the very best of human diagnostic systems for the older ones.</p>

<p>
Whilst servicing your car we will also give it a checkover so we
can bring to your attention any items requiring immediate/future consideration , we find this helps to budget for the future and prevent smaller jobs becoming major repair items.</p>
                                    <p>Servicing on all models undertaken.</p>
</aside>
                                <EmailButton href={"mailto:info@strasse.co.uk"}>Enquire for prices</EmailButton>

                            </Col>
                        </Row>



                        <Row className='pt-5 mt-5 pb-5'>
                            <Col>
                            <aside className='services-page-content'>
                            <h5 className='mont-bold'>WHEEL<br/>ALIGNMENT</h5>
           
                                <p>
                                It is good practice to regularly check and adjust wheel alignment its relatively easy for wheel alignment ot knocked out by some driving surfaces such as pot holes rough surfaces etc or track day use. Proper wheel alignment also has a positive effect on the lifespan of your tyres. Whatever Porsche you drive, it is important to routinely check and adjust the wheel alignment as it has a huge impact on the driving experience, tyre wear and tyre life. We would always recommend an alignment on tyre changes as a minimum.</p>
</aside>
                                <EmailButton href={"mailto:info@strasse.co.uk"}>Enquire</EmailButton>

                            </Col>

                            <Col  md={7} xs={12}>
                                <img className={'w-100'}  src={'/images/ims.jpg'} />
                            </Col>

                        </Row>


                        <Row className='pt-5 pb-5'>
                        <Col  md={7} xs={12}>
                                <img className={'w-100'}  src={'/images/refurb.jpg'} />
                            </Col>

                            <Col>
                            <aside className='services-page-content'>
                            <h5 className='mont-bold'>WHEEL<br/>REFURBISHMENT</h5>
           
                                <p>
                                We provide a full alloy wheel refurbishment service. This includes diamond cut, factory and custom finishes. We also stock full range of wheel centres. </p>
</aside>
                                <EmailButton href={"mailto:info@strasse.co.uk"}>Enquire</EmailButton>

                            </Col>
</Row>
                            <Row className='pt-5 pb-5'>
                            <Col>
                            <aside className='services-page-content'>
                            <h5 className='mont-bold'>DETAILING</h5>
           
                                <p>
                                We have the facility to provide and full and comprehensive detailing service. From a service valet to a full paint correction – call or email for details.</p>
</aside>
                                <EmailButton href={"mailto:info@strasse.co.uk"}>Enquire</EmailButton>

                            </Col>

                            <Col  md={7} xs={12}>
                                <img className={'w-100'}  src={'/images/detail.jpg'} />
                            </Col>

                        </Row>

                        <Row  className={'pt-5 pb-5'}>
                            <Col  md={7} xs={12}>
                                <img className={'w-100'}  src={'/images/bore.jpg'} />
                            </Col>
                            <Col>
                            <aside className='services-page-content'>
                            <h5 className='mont-bold'>AIR CON<br/>SERVICE</h5>
                                <p>
                                We can provide an air conditioning service for all models ranging from a simple pressure test and re-gas to full pipe, condenser and compressor replacement.</p>
<p>Call for more details, prices and workshop availability.</p>
</aside>
                                <EmailButton href={"mailto:info@strasse.co.uk"}>Enquire</EmailButton>

                            </Col>
                        </Row>


                        <Row className='pt-5 pb-5'>

                            <Col>
                            <aside className='services-page-content'>
                            <h5 className='mont-bold'>IMS made simple</h5>
           
                                <p>
                                Three little letters to keep Porsches drivers wide awake at night. “IMS” stands for intermediate shaft. The intermediate shaft is the shaft that runs all the way through the engine. It serves the purpose of driving the engine’s crankshaft to indirectly drive
the camshafts on either side of the engine. The failure occurs when the bearing upon which the shaft sits breaks down. This is thought to be a result of the bearing being unable to withstand the physical and thermal load or insufficient lubrication.
If you’re a GT2, GT3 or Turbo owner you are free to stop scrolling. All Gt2, GT3 and Turbo models are free from IMS bearing failure. Whilst IMS bearing failure results in a catastrophic engine failure, there are many different manufacturers that offer a pro-active resolution to this issue. Something to consider if you are thinking about IMS bearing replacement is the life of the clutch and the rear main oil seal, all of these are much easier when done in tandem.
                                </p>
                                <p>Speak with a member of our team for advice or a quotation</p>
</aside>
                                <EmailButton href={"mailto:info@strasse.co.uk"}>Enquire</EmailButton>

                            </Col>
                            <Col  md={7} xs={12}>
                                <img className={'w-100'}  src={'/images/ims-image.jpg'} />
                            </Col>

</Row>
                            <Row className='pt-5 pb-5'>

                            <Col  md={7} xs={12}>
                                <img className={'w-100'}  src={'/images/bore-score.jpg'} />
                            </Col>
                            <Col>
<aside className='services-page-content'>
                            <h5 className='mont-bold'>BORESCORE</h5>
           
                                <p>
                                Our workshop is fully equipped to offer clear and accurate ‘bore score’ diagnosis, repair and advice.
                                </p>
                                <p>We believe that the problem surrounding bore score on
the m96 and m97 engines is in fact quite over exaggerated and only affects a small percentage of vehicles. Regular oil changes, longer journeys letting the engines get up to running temperature and general mechanical sympathy can all help prevent scored bore.</p>
<p>Speak with a member of our team for advice or a quotation</p>
</aside>
                                <EmailButton href={"mailto:info@strasse.co.uk"}>Enquire</EmailButton>

                            </Col>


                        </Row>

                        <Row className={'pt-5 pb-5'}>
                            <Col>
                            <aside className='services-page-content'>
                            <h5 className='mont-bold'>ENGINE & gearbox<br />REBUILDS</h5>
                                <p>
                                We are very well known and now only one of a very few people who can carry out to highest standard air cooled engine re- builds, our team led by Dave the Legend Sunderland is a mine
of expertise and experience that only over 50 years working on these cars can bring – knowledge which is being carefully and skilfully handed one to the apprentices in our teaching. The water cooled and race car engine re-builds are led by Chris Dyer with a wealth of experience and knowledge at his fingertips. We have solutions for both air-cooled and water-cooled and we are very proud to be training the new generations to care for these cars well into the future.</p>
</aside>
                                <EmailButton href={"mailto:info@strasse.co.uk"}>Enquire</EmailButton>

                            </Col>

                            <Col md={7} xs={12}>
                                <img className={'w-100'}  src={'/images/rms.jpg'} />
                            </Col>

                        </Row>










                        <Row  className={'pt-5 pb-5'}>
                            <Col  md={7} xs={12}>
                                <img className={'w-100'}  src={'/images/motorsport-service.png'} />
                            </Col>
                            <Col>
                            <aside className='services-page-content'>
                            <h5 className='mont-bold'>MOTORSPORT<br/>PREP</h5>
                                <p>
                                Race and Track Prep, Race Support, and Race Build, Race car hire, are all part of the Motorsport packages we can offer from our workshops. </p>
<p>
Strasse are already very well known for our race car prep and full builds from road car to race car, led by Chris Dyer, who also pilots our own Cayman S – we were one of the first in the UK
to build the Cayman for race purposes others quickly followed as it became a formidable race car. We have the facility to
build a race car to full regulations and can also supply full race support and transportation. Costs for this are on an individual basis and more information and general discussion can be had by calling us and speaking to David Forrest whose family have a long heritage of race history behind them. With multiple champions and team championships with 944/968/964/911/ 996/997/986/987 undertheStrassebannerwethinkweknow what it takes to be a winner.</p>
                                </aside>
                                <EmailButton href={"mailto:info@strasse.co.uk"}>Enquire</EmailButton>

                            </Col>
                        </Row>



                        <Row className='pt-5 pb-5'>
                            <Col>
                            <aside className='services-page-content'>
                            <h5 className='mont-bold'>PPF and<br/>Paint Correction</h5>
           
                                <p>
                                We can provide a full service of paint correction, detail and the paint protection film – these are bespoke packages so for individual pricing and to discuss whats right for your particular car please give us a call.
                               </p>
</aside>
                                <EmailButton href={"mailto:info@strasse.co.uk"}>Enquire</EmailButton>

                            </Col>

                            <Col  md={7} xs={12}>
                                <img className={'w-100'}  src={'/images/ppf.png'} />
                            </Col>

                        </Row>

                        <Row className={'pt-5  pb-5'}>
                        <Col  md={7} xs={12}>
                                <img className={'w-100'}  src={'/images/bodyshop.png'} />
                            </Col>


                            <Col>
                            <aside className='services-page-content'>
                            <h5 className='mont-bold'>BODYSHOP</h5>
                                <p>
                                At Strasse we can provide a variety of body shop services. Full resprays, wheel arch corrosion repairs, windscreen scuttle repairs, chip and scuff repairs and dent removal. Call or email to discuss your requirements.
                                </p>
<p>
PORSCHE INSURANCE REPAIRS<br/>
Similarly with body repairs we can undertake to an extremely high standard all body repairs and deal with the insurance company and their engineers.
We are fully able to assess damage and understand what parts are needed and carrying out the rebuild and any paintwork to the highest of standards.</p>
<p>
As recognised Porsche Specialists if your car has an after market warranty we can undertake warranty work – please ask for further details. Dealing with warranty providers can sometimes be a little stressful and they require a more technical approach to ensure they understand the work needed and more importantly why it is needed.
</p>
</aside>
                                <EmailButton href={"mailto:info@strasse.co.uk"}>Enquire</EmailButton>

                            </Col>

                        </Row>





                    </Col>







                    <Col style={{justifyContent: 'flex-start', background: '#E2E0DF', flexDirection: 'column'}} xs={3} className={'d-none d-lg-flex pb-5'}>
                        <p className={'pt-3'}>Contact Strasse.</p>

                        <p className={'pt-3'}>We are open Monday to Saturday - and on Sunday by appointment.</p>

                        <List className={'pt-3 pb-0'}>
                            <li>Unit 911</li>
                            <li>Bridge Road</li>
                            <li>Holbeck</li>
                            <li>Leeds</li>
                            <li>LS11 9UG</li>
                            <li>West Yorkshire</li>
                            <li>&nbsp;</li>
                            <li>Tel: 0113 2340 911</li>
                            <li>Email: info@strasse.co.uk</li>
                        </List>

                        <p className={'pt-3'}>
                            For our latest news and to see live projects
                            please follow our social media pages on
                        </p>

                    </Col>
                </Row>
            </Container>



            {/* <Container fluid style={{background: '#E2E0DF'}}>
                <Row>

                    <Col className={'p-5 text-center'}>
                   
                    <p>     
                   Keeping your Porsche correctly serviced is not only important from a maintenance point of view but also comes into play when re-selling your car. Buyers like to see a good comprehensive history from a respected source. 
                   </p>
                   <p>
                   The team we have here is vastly experienced and both with service and maintenance, our service stamp in a guarantee and maintenance book is always looked upon very favourably by those in the Porsche world.
Our technicians have  been in the Porsche environment for decades and some have had  main dealer experience. We use Millers Oils lubricants, which are fully Porsche approved and we have found them to be exceptional when used in our high performance race cars.  We ensure only the highest quality of parts are used.  We run the latest diagnostic systems, for the modern cars and the very best of human diagnostic systems for the older ones. 
</p>
<p>
Whilst servicing your car we will also give it a checkover so we can bring to your attention any items requiring immediate/future consideration , we find this helps to budget for the future and prevent smaller jobs becoming major repair items. 
</p>
<p>
Servicing on all models undertaken
</p>
<p>
For prices or more information just <a href="mailto:info@strasse.co.uk?subject=Enquire about Servicing&body=Hi, Im interested in a Service.">enquire</a>
</p>

                    </Col>
                    </Row>
                    <Row>
                    <Col className={'p-0'}>
                        <img src={'images/service-image-2.png'} className={'w-100'} alt={''} />
                    </Col>
                    <Col className={'p-0'}>
                        <img src={'images/service-image-1.png'} className={'w-100'} alt={''} />
                    </Col>
                </Row>

            </Container> */}

            <Footer />

        </>
    );
}


const List = styled.ul`
  padding: 0;
  list-style: none;
    
  li {
    height: 20px !important;
    margin-bottom: .5rem;
    display: flex;
    justify-content: space-between;
    align-items: baseline;  
    padding-right: 1rem;
  }
  
  span {
    display: inline-block;
  }

`;


const EmailButton = styled.a`
    text-decoration: none;
    background-color: white;
    color: #090808;
    border-radius: 200px;
    border: 1px solid #090808;
    padding: 0 1rem;
`;