import React, { useState, useEffect } from 'react';

import Container from 'react-bootstrap/Container';
import * as contentful from 'contentful';
import ProkoNavigation from "../components/nav";
import Footer from "../components/footer";
import CarListings from "../components/car-listings";
import Banner from "../components/banner";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CarsFilter from "../components/car-listings/filter";
import Car from "../components/car-listings/car";
import styled from "styled-components";
import {Button} from "react-bootstrap";
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import Person from '../components/person';

var client = contentful.createClient({
    space: 'de8somyyf2bq',
    accessToken: '43yRDEal8Jm1ePvYf8YqAmR69l-FIZBue38IviAb26Q'
})

export default () => {
    const [news, setNews] = useState([]);

    useEffect(async () => {
        const p = await client.getEntries({content_type: "news"});
        setNews(p.items);
    }, news)

    const Content = () => {
        return (
            <>
                <h5 className={'mt-3  mont-bold'}>DAVE WINS TALENT CONTEST</h5>
                <p className={''}>17th May 21</p>
                <p style={{fontSize: '0.7rem', lineHeight: '1rem'}} className={''}>You must know df right? In the early 90’s joined porsche – he has been eyeing porsches up since he was a little lad - and along with Dave Sunderland was fully porsche trained before he set up Strasse with Dave Sunderland in 1994 never looking back – and never ever losing his porsche passion – with motorsport and cars in the family blood his enthusiasm never wanes.</p>
            </>
        );
    }
    return (
        <>
            <ProkoNavigation showLogo />

            <div className={'mt-5'}>
                <img className={'w-100'} src={'/images/news-banner.png'} alt={''} />
            </div>

            <Container fluid>


                <Row>
                    <Col className={'text-center pt-5 pb-5'}>
                        <h5>NEWS</h5>
                    </Col>
                </Row>

                <Row>
                    {news.map(({fields}) => (
                        <Col key={fields.title} xs={12} md={4} lg={3} className={''}>
                            {(fields.image.fields && fields.image.fields.file) && (
                                <img src={fields.image.fields.file.url} className={'w-100'} />
                            )}
                            <h5 className={'pl-3 mt-3 mont-bold'}>{fields.title}</h5>
                            <p className={'pl-3'}>{fields.date}</p>
                            <p style={{fontSize: '0.7rem', lineHeight: '1rem'}} className={'pl-3'}>{fields.content}</p>
                        </Col>
                    ))}
                </Row>

            </Container>


            <Footer />

        </>
    );
}

const EmailButton = styled.a`
text-decoration: none;
    background-color: #E2E0DF;
    color: #090808;
    border-radius: 200px;
    border: 1px solid #090808;
    padding: 0 1rem;
`;

const List = styled.ul`
  padding: 0;
  list-style: none;
`;