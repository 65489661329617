import React from 'react';

import ProkoNavigation from "../components/nav";
import Footer from "../components/footer";
import CarListings from "../components/car-listings";
import Container from "react-bootstrap/Container";
import Banner from "../components/banner";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styled from "styled-components";

export default () => {

    return (
        <>
            <ProkoNavigation showLogo />

            <div className={'mt-5'}>
                <img className={'w-100'} src={'/images/servicing-banner.png'} alt={''} />
            </div>

            <Container fluid style={{background: '#EB212D'}}>
                <Row>
                    <Col className={'text-center pt-5 pb-5'}>
                        <h5 className={'mb-3'}>THE TEAM BEHIND THE NORTH’S LEADING INDEPENDENT PORSCHE SPECIALIST.</h5>

                        <p className={'mr-auto ml-auto w-75 text-center'}>
                            Strasse - Servicing for Porsches - Leeds<br />
                            Your car can find its own Porsche haven where it will be cared for and pampered.<br/>
                            We guarantee a personal service, with everyone fully involved on the workshop floor, and with tried, trusted and experienced staff.
                        </p>
                    </Col>
                </Row>

            </Container>


            <Container fluid style={{background: '#E2E0DF'}}>
                <Row>

                    <Col xs={5} className={'pr-5'}>

                    Keeping your Porsche correctly serviced is not only important from a maintenance point of view but also comes into play when re-selling your car. Buyers like to see a good comprehensive history from a respected source. The team we have here is vastly experienced and both with service and maintenance, our service stamp in a guarantee and maintenance book is always looked upon very favourably by those in the Porsche world.
Our technicians have  been in the Porsche environment for decades and some have had  main dealer experience. We use Millers Oils lubricants, which are fully Porsche approved and we have found them to be exceptional when used in our high performance race cars.  We ensure only the highest quality of parts are used.  We run the latest diagnostic systems, for the modern cars and the very best of human diagnostic systems for the older ones. Whilst servicing your car we will also give it a checkover so we can bring to your attention any items requiring immediate/future consideration , we find this helps to budget for the future and prevent smaller jobs becoming major repair items. 

Servicing on all models undertaken

For prices or more information just enquire

                        {/* <h5 className={'mb-3 pt-5'}>PORSCHE SERVICING PRICES</h5>
                        <p>PLEASE NOTE THAT PRICES EXCLUDE VAT AND ARE SUBJECT TO CHANGE DUE TO FLUCTUATING COSTS OF MATERIALS.</p>

                        <List>
                            <li><span>911 3.2</span>	<span>6K: £253.45 - 12K: £403.45</span></li>
                            <li><span>911 SC</span>	<span>6K: £253.45 - 12K: £403.45</span></li>
                            <li><span>911 TURBO 3.3</span>	<span>6K: £263.45 - 12K: £479.45</span></li>
                            <li><span>924</span>	<span>6K: £173.45 - 12K: £273.45</span></li>
                            <li><span>924S</span>	<span>6K: £203.45 - 12K: £313.45</span></li>
                            <li><span>924 TURBO</span>	<span>6K: £203.45 - 12K: £303.45</span></li>
                            <li><span>928</span>	<span>6K: £233.45 - 12K: £373.45</span></li>
                            <li><span>944</span>	<span>6K: £203.45 - 12K: £323.45</span></li>
                            <li><span>944 S</span>	<span>6K: £203.45 - 12K: £323.45</span></li>
                            <li><span>944 S2</span>	<span>6K: £203.45 - 12K: £333.45</span></li>
                            <li><span>944 TURBO</span>	<span>6K: £203.45 - 12K: £313.45</span></li>
                            <li><span>944 TURBO</span>	<span>6K: £203.45 - 12K: £343.45</span></li>
                            <li><span>964 C2 & C4</span>	<span>6K: £263.45 - 12K: £479.45</span></li>
                            <li><span>968 ALL</span>	<span>6K: £203.45 - 12K: £353.45</span></li>
                        </List> */}

                    </Col>
                    <Col className={'pl-5 pr-0'}>
                        <img src={'images/service-image-1.png'} className={'w-100'} alt={''} />
                        <img src={'images/service-image-2.png'} className={'w-100'} alt={''} />
                    </Col>
                </Row>

            </Container>


            <Footer />

        </>
    );
}


const List = styled.ul`
  padding: 0;
  list-style: none;
    
  li {
    height: 20px !important;
    display: flex;
    justify-content: space-between;
    align-items: baseline;  
    padding-right: 1rem;
  }
  
  span {
    display: inline-block;
  }

`;