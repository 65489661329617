import React from 'react';

import {Switch, Route} from 'react-router-dom';
import HomeContainer from './containers/home';
import CarsContainer from './containers/cars';
import PartsContainer from './containers/parts';
import CarContainer from './containers/car';
import AboutContainer from './containers/about';
import ServiceContainer from './containers/service';
import ServicesContainer from './containers/services';
import ContactContainer from './containers/contact';
import MotorsportContainer from './containers/motorsport';
import NewsContainer from './containers/news';
import TestimonialsContainer from './containers/testimonials';
import EngineRoomContainer from './containers/engine-room';

import './App.css';
import ScrollToTop from './scroll';

function App() {
  return (
    <>
        <ScrollToTop />

    <Switch>

      <Route exact path="/">
        <HomeContainer />
      </Route>

        <Route exact path="/about">
            <AboutContainer />
        </Route>

        <Route exact path="/services">
            <ServicesContainer />
        </Route>

        <Route exact path="/service">
            <ServiceContainer />
        </Route>

        <Route exact path="/news">
            <NewsContainer />
        </Route>


        <Route exact path="/engineroom">
            <EngineRoomContainer />
        </Route>

        <Route exact path="/testimonials">
            <TestimonialsContainer />
        </Route>


        <Route exact path="/motorsport">
            <MotorsportContainer />
        </Route>

        <Route exact path="/contact">
            <ContactContainer />
        </Route>

        <Route exact path="/cars">
            <CarsContainer />
        </Route>

        <Route exact path="/parts">
            <PartsContainer />
        </Route>

        <Route exact path="/cars/:car">
            <CarContainer />
        </Route>

    </Switch>
    </>

  );
}

export default App;
