import React, {useEffect, useState} from 'react';
import axios from 'axios';
import * as contentful from 'contentful';

import ProkoNavigation from "../components/nav";
import Footer from "../components/footer";
import CarListings from "../components/car-listings";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

var client = contentful.createClient({
    space: 'de8somyyf2bq',
    accessToken: '43yRDEal8Jm1ePvYf8YqAmR69l-FIZBue38IviAb26Q'
})

export default () => {
    const [cars, setCars] = useState([]);

    useEffect(async () => {
        const p = await client.getEntries({content_type: "cars"});
        setCars(p.items);
    }, cars)


    // useEffect(() => {
    //     axios.get('https://strasse-api.herokuapp.com/cars',
    //         {
    //             headers: {
    //             'Access-Control-Allow-Origin' : '*',
    //             'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    //             }
    //         }).then((r) => setCars(r.data))
    // }, [cars.length])

    // let results = [];

    // if(cars && cars.stockResponse && cars.stockResponse.results) {
    //     results = cars.stockResponse.results;
    // }

    return (
        <>
            <ProkoNavigation showLogo />

            <div className={'mt-5'}>
                <img className={'w-100'} src={'/images/car-banner.jpg'} alt={''} />
            </div>

            <Container fluid>
                <Row>
                    <Col className={'text-center pt-5 pb-5'}>
                        <h5 className={'mb-3'}>STRASSE. HANDPICKED CARS FOR SALE</h5>

                        <p className={'mr-auto ml-auto w-75'}>
                            As the North’s leading independent Porsche specialist we offer a selection of truly hand-picked cars for sale. You will usually find those on sale are ones  that we have serviced and cared for for many years, or even previously sold as customers do tend to return when they change models. But, if the car of your dreams isn’t here, call us on 0113 234 0911 - our extensive contacts and excellent reputation in the Porsche world, means we can often find you just what you’re looking for.</p>
                    </Col>
                </Row>

            </Container>
        {cars.length !== 0 && (
            <CarListings cars={cars} />
        )}

        {cars.length === 0 && (
            <div style={{textAlign: 'center', padding: '1rem 1rem 3rem 1rem'}}>
                <h2>There are currently no cars for sale.</h2>
            </div>
        )}
            
            <Footer />

        </>
    );
}