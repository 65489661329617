import React from 'react';

import Container from 'react-bootstrap/Container';

import ProkoNavigation from "../components/nav";
import Footer from "../components/footer";
import CarListings from "../components/car-listings";
import Banner from "../components/banner";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CarsFilter from "../components/car-listings/filter";
import Car from "../components/car-listings/car";
import styled from "styled-components";
import {Button} from "react-bootstrap";

import Person from '../components/person';

export default () => {

    return (
        <>
            <ProkoNavigation showLogo />

            <div className={'mt-5'}  style={{position: 'relative'}}>
            <img style={{position: 'absolute', width: '300px', top: '50%', left: '3rem'}} src={'/images/Strasse-engine-room-title.png'} alt={''} />
                <img className={'w-100'} src={'/images/engineroom-banner.png'} alt={''} />
            </div>

            <Container fluid>


                <Row>
                    <Col className={'text-center pt-5 pb-5'}>
                        <h5>PORSCHE ENGINE REBUILDS</h5>
                        <p className={'mt-3 mr-auto ml-auto w-75'}>
                            Repair, upgrade or rebuild, we have technicians with vast and varied experience in all variants of models and years. Call or email us to discuss your requirements and ask to speak to Dave or Sam. 0113 2340 911.
                        </p>
                    </Col>
                </Row>

                <img className={'w-100'} src={'/images/engine.jpg'} />


            </Container>


            <Container fluid>
                <Row>
                    <Col className={'pt-5'} style={{background: '#FFF'}}>

                        <Row className='mb-3'>
                            <Col>
                            <img className={'w-100'}  src={'/images/Strasse-engine-room-image-1.png'} />
                            </Col>
                            <Col>
                            <img className={'w-100'}  src={'/images/Strasse-engine-room-image-2.png'} />
                            </Col>
                        </Row>
                        <Row className='mb-3'>
                            <Col>
                            <img className={'w-100'}  src={'/images/Strasse-engine-room-image-3.png'} />
                            </Col>
                            <Col>
                            <img className={'w-100'}  src={'/images/Strasse-engine-room-image-4.png'} />
                            </Col>
                        </Row>
                        <Row className='mb-3'>
                            <Col>
                            <img className={'w-100'}  src={'/images/Strasse-engine-room-image-5.png'} />
                            </Col>
                            <Col>
                            <img className={'w-100'}  src={'/images/Strasse-engine-room-image-6.png'} />
                            </Col>
                        </Row>
                        <Row className='mb-3'>
                            <Col>
                            <img className={'w-100'}  src={'/images/Strasse-engine-room-image-7.png'} />
                            </Col>
                            <Col>
                            <img className={'w-100'}  src={'/images/Strasse-engine-room-image-8.png'} />
                            </Col>
                        </Row>
                        <Row className='mb-3'>
                            <Col>
                            <img className={'w-100'}  src={'/images/Strasse-engine-room-image-9.png'} />
                            </Col>
                            <Col>
                            <img className={'w-100'}  src={'/images/Strasse-web-s10.png'} />
                            </Col>
                        </Row>
                        <Row className='mb-3'>
                            <Col>
                            <img className={'w-100'}  src={'/images/Strasse-web-s11.png'} />
                            </Col>
                            <Col>
                            <img className={'w-100'}  src={'/images/Strasse-web-s12.png'} />
                            </Col>
                        </Row>
                      


                    </Col>
                    <Col style={{justifyContent: 'flex-start', background: '#E2E0DF', flexDirection: 'column'}} xs={3} className={'d-none d-lg-flex pb-5'}>
                        <p className={'pt-3'}>Contact Strasse.</p>

                        <p className={'pt-3'}>We are open Monday to Saturday - and on Sunday by appointment.</p>

                        <List className={'pt-3 pb-0'}>
                            <li>Unit 911</li>
                            <li>Bridge Road</li>
                            <li>Holbeck</li>
                            <li>Leeds</li>
                            <li>LS11 9UG</li>
                            <li>West Yorkshire</li>
                            <li>&nbsp;</li>
                            <li>Tel: 0113 2340 911</li>
                            <li>Email: info@strasse.co.uk</li>
                        </List>

                        <p className={'pt-3'}>
                            For our latest news and to see live projects
                            please follow our social media pages on
                        </p>

                    </Col>
                </Row>
            </Container>

            <Container fluid className='p-0' style={{background: '#ec0e22'}}>

            {/*<Row>*/}
            {/*        <Col>*/}
            {/*            <img className='w-100' src='/images/brake-advert.png' alt='' />*/}
            {/*        </Col>*/}
            {/*    </Row>*/}

                <Row className='p-4 pb-0'>   
                    <Col>
                        <img className='w-50' src='/images/Strasse-logo.png' alt='' />
                    </Col>
                    <Col>
                    </Col>
                </Row>
                <Row className='pl-4 pr-4 pb-4'>
                    <Col md={6} xs={12}>
                            <p style={{color: '#fff'}} className='mt-0 mb-0'>Official suppliers of</p>
                            <h2 style={{color: '#fff'}}>CARBON CERAMIC DISCS.</h2>
                            <EmailButton href={"mailto:info@strasse.co.uk?subject=Ceramic breaks&body=Hi, Im interested in the Ceramic breaks."}>Available for 991-992</EmailButton>
                    </Col>
                    <Col>
                        <img className='w-50' src='/images/surface-logo.png' alt='' />
                    </Col>
                </Row>

                <Row className='p-4'>
                    <Col>
                        <img className='w-100' src='/images/brake-image.png' alt='' />
                    </Col>
                </Row>

                <Row className='p-4'>
                    <Col md={6} xs={12}>
                       <h4 style={{color: '#fff'}}>Surface Transforms has developed unique patented next-generation Carbon-Ceramic Technology that provides the ultimate braking performance for road and track. Here’s seven reasons why you need this technology on your vehicle:</h4>
                    </Col>
                    <Col>
                        <ul style={{color: '#fff', fontSize: '14px'}}>
                            <li>Weight savings of up to 70% compared to iron brakes (typically 20kg of unsprung weight) </li>
                            <li>Improved handling and driveability</li>
                            <li>Improved NVH (less noise, vibration and harshness)</li>
                            <li>Improved performance (in both wet and dry conditions)</li>
                            <li>Reduced brake wear – giving increased life</li> 
                            <li>Corrosion Free</li>
                            <li>Outstanding performance, even from cold</li>
                        </ul>
                        <EmailButton href={"mailto:info@strasse.co.uk?subject=Ceramic breaks&body=Hi, Im interested in the Ceramic breaks."}>Enquire for your vehicle now</EmailButton>
                    </Col>
                </Row>

            </Container>

            <Footer />

        </>
    );
}


const List = styled.ul`
  padding: 0;
  list-style: none;
`;

const EmailButton = styled.a`
text-decoration: none;
background-color: #ec0e22;
color: #fff;
    border-radius: 200px;
    border: 1px solid #fff;
    padding: 0 1rem;
    
            display: inline-block;
        text-decoration: none !important;
        cursor: pointer;
        
        &:hover{
        color: #fff;
        }
\`;
`;