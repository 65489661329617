import React from 'react';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";

export default ({alternate = false}) => {

    return (

        <Container fluid>



        <Row>

            <Col sm={12} md={6} className={'text-center p-0 pt-5 pb-3'} style={{background: '#080605', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <div className={'pt-3 pb-3'}>
                    <img width={300} src={'/images/text-logo.png'} alt={''} />

                    <p className={'red mt-5'}>
                        We are open Monday to Saturday and on Sunday by appointment.
                    </p>
                    <p className={'red'}>
                        Unit 911, Bridge Road, Holbeck, Leeds	LS119UG	West Yorkshire Tel: 0113 2340 911	Email: <a className={'red'} style={{textDecoration: 'none'}} href={"mailto:info@strasse.co.uk"}>info@strasse.co.uk</a>
                    </p>

                    <p>
                        <img width={"30"} src={'/images/strasse-icon.png'} alt={''} />
                    </p>

                    <p className={'red'}>
                        <small>© Copyright 2023, All rights reserved.	Design by ®Rich Colvill	Site build by PROKO©</small>
                    </p>
                </div>
            </Col>

            <Col sm={6} className={'p-0 d-none d-md-block'}>
                {!!(alternate) && (
                  <div style={{
                      width: '100%',
                      height: '100%',
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      backgroundImage: 'url(/images/sign-team.png)'
                  }}></div>
                )}
                {!(alternate) && (
                    <div style={{
                        width: '100%',
                        height: '100%',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundImage: 'url(/images/sign.png)'
                    }}></div>
                )}
            </Col>
        </Row>
        </Container>
    );
}