import React from 'react';
import { Link } from 'react-router-dom';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import styled from "styled-components";
import {Button} from "react-bootstrap";


export default ({className = '', name, title, subtitle, description, image, date, founder = false}) => {

    return (
        <Col xs={6} className={`pb-3 ${className}`}>
            <Row>
                <Col>
                    <img className={'img-fluid'} src={`/images/people/${image}`} alt='' />
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={6} className={'pl-3 pt-3 text-left text-uppercase'}>
                    <h5 style={{fontWeight: 700, fontSize: '1rem'}} className={'mont-bold'}>{name}</h5>
                </Col>
                <Col className={'pt-3'}>
                    <List>
                        <li>
                            <h5>{title}<br/>{subtitle}</h5>

                            {!!(founder) && (<h5>Founded<br/>1994</h5>)}
                            {!(founder) && (<h5>Joined<br/>{date}</h5>)}
                        </li>
                    </List>
                </Col>
                {/*<Col xs={4} className={'text-right pr-3 pt-3'}>*/}

                {/*</Col>*/}
            </Row>

            <Row>
                <p className={'p-3'} style={{fontSize: '0.7rem', lineHeight: '1rem'}}>{description}</p>
            </Row>
        </Col>
    );
}

const DetailsButton = styled(Button)`
    background-color: #FFF;
    color: #090808;
    border-radius: 200px;
    border: 1px solid #090808;
    padding: .2rem 1rem 0;
    font-size: 0.7rem;
`;

const List = styled.ul`
  padding: 0;
  list-style: none;
  
  li {
    height: 15px !important;
    display: flex;
    justify-content: space-between;
    align-items: baseline;  
  }
  
  h5 {
    margin: 0;
    font-size: .7rem;
  }
  
  p {
    padding: 0;
    font-size: .7rem !important;
  }  
`;