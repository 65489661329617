import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

import Car from './car';
import CarsFilter from './filter';

import styled from "styled-components";
import { Button } from "react-bootstrap";

export default ({cars}) => {

    return (

        <Container fluid>
            {/*<CarsFilter sortByPrice={sortByPrice} sortByYear={sortByYear} />*/}
            <Row>
                <Col className={'pt-3'} style={{background: '#FFF'}}>
                    <Row>
                        {cars.map(car => ( <Car {...car} />))}
                   </Row>

                </Col>
            </Row>
        </Container>
    );
}



const EmailButton = styled.a`
text-decoration: none;
    background-color: #E2E0DF;
    color: #090808;
    border-radius: 200px;
    border: 1px solid #090808;
    padding: 0 1rem;
`;