import React from 'react';
import Carousel from "react-bootstrap/Carousel";
import { Parallax } from 'react-parallax';

export default () => {

    const text = [
        'Welcome to Strasse, the North’s Leading Independent Porsche Specialist.',
        'Our background in motorsport means your Porsche is in good hands.',
        'Shop our magnificent marques. All officially approved and checked by us.'
    ];

    return (
        <Carousel id={'carousel'} style={{background: 'url(/images/slider-background.png)', backgroundSize: 'cover'}}>

            {[1, 2, 3].map(i => (
                <Carousel.Item>
                  <img
                            className="d-block w-100"
                            src={`/images/slide-${i}.png`}
                            alt=""
                        />

                    <Carousel.Caption>
                        <p className={''}>{text[i - 1]}</p>
                    </Carousel.Caption>
                </Carousel.Item>
            ))}

        </Carousel>
    );
}
