import React, {useEffect, useState, useRef} from 'react';
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col"

export default () => {
    const carRef = useRef();
    const [position, setPosition] = useState(0);

    const scroll = () => {
        const winScroll = document.body.scrollTop || document.documentElement.scrollTop

        setPosition(winScroll);
    }

    useEffect(() => {
        window.addEventListener('scroll', scroll)

        if(position > 400) {
           document.getElementById('logo').classList.add('show');
        } else {
            document.getElementById('logo').classList.remove('show');
        }

        if(position > 300) {
            // lets move the car

            carRef.current.style.left = ((position) - 1400) + 'px'
        }

        return () => {
            window.removeEventListener('scroll', scroll)
        };
    }, [position])

    return (
        <ParallaxProvider>
            <Container fluid>
                <Row>
                    <Col style={{position: 'relative'}} className={'p-0'} >
                        <img className={'w-100'} src={'/images/road-background.png'} alt={''} />
                        <img className={'d-none d-lg-block'} ref={carRef} style={{position: 'absolute', top: '50%', left: -1000, transform: 'translateY(-50%)', zIndex: 11}} width='1300' src={'/images/car.png'} alt={''} />
                    </Col>
                </Row>
            </Container>

        </ParallaxProvider>
    );
}