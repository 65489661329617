import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';
import { Link } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 600,
        backgroundColor: '#080605',
        paddingLeft: '3rem',
        paddingRight: '3rem',
        paddingTop: '3rem',
        fontFamily: 'Montserrat'
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));


export default function Draw(props) {

    const classes = useStyles();
    const [openShop, setOpenShop] = React.useState(false);
    const [openService, setOpenService] = React.useState(false);
    const [active, setActive] = React.useState(false);

    const handleShopClick = () => {
        setOpenShop(!openShop);
    };

    const handleServiceClick = () => {
        setOpenService(!openService);
    };

    return (
        <Drawer id={'nav-draw'} className={'pl-5 pr-5'} anchor={'left'} open={props.open} onClose={() => {
            setOpenShop(false);
            setOpenService(false);
            props.closeMenu();
        }}>

    <CloseIcon onClick={() => {
        setOpenShop(false);
        setOpenService(false);
        props.closeMenu();
    }} fontSize={'large'} style={{cursor: 'pointer', zIndex: 5, position: 'absolute', top: '1rem', right: '1rem', color: 'white'}} />

            <List component="nav"  className={classes.root}>

                <ListItem button onClick={props.closeMenu}>
                    <Link to={'/about'}>
                        <ListItemText primary="About us" />
                    </Link>
                </ListItem>

                <ListItem button onClick={handleShopClick}>
                    {/*{!!(openShop) && (<ListItemText className={'text-white'} style={{color: '#000', opacity: .6}} primary="Shop" />)}*/}
                    {/*{!(openShop) && (<ListItemText primary="Shop" />)}*/}
                    {/*/!*<ListItemText primary="Shop" />*!/*/}
                    {/*/!*{openShop ? <ExpandLess color={'#6c757d'} /> : <ExpandMore color={'#6c757d'} />}*!/*/}
                    {/*<Collapse in={openShop} timeout="auto" unmountOnExit>*/}
                    {/*    <List component="div" disablePadding>*/}
                    {/*        <ListItem button className={classes.nested}>*/}
                    {/*            <Link to={'/cars'}>*/}
                    {/*                <ListItemText primary="> Cars" />*/}
                    {/*            </Link>*/}
                    {/*        </ListItem>*/}
                    {/*        <ListItem button className={classes.nested}>*/}
                    {/*            <Link to={'/parts'}>*/}
                    {/*                <ListItemText primary="> Parts" />*/}
                    {/*            </Link>*/}
                    {/*        </ListItem>*/}
                    {/*    </List>*/}
                    {/*</Collapse>*/}
                    <Link to={'/cars'}>
                                        <ListItemText primary="Cars for Sale" />
                                    </Link>
                </ListItem>

                <ListItem button onClick={props.closeMenu}>
                    <Link to={'/services'}>
                        <ListItemText primary="Servicing" />
                    </Link>
                </ListItem>

                <ListItem button onClick={props.closeMenu}>
                    <Link to={'/motorsport'}>
                        <ListItemText primary="Motorsport" />
                    </Link>
                </ListItem>
                <ListItem button onClick={props.closeMenu}>
                    <Link to={'/engineroom'}>
                    <ListItemText primary="Engine Room" />
                    </Link>
                </ListItem>
                <ListItem button onClick={props.closeMenu}>
                    <Link to={'/news'}>
                    <ListItemText primary="News" />
                    </Link>
                </ListItem>
                {/*<ListItem button onClick={props.closeMenu}>*/}
                {/*    <Link to={'/testimonials'}>*/}
                {/*    <ListItemText primary="Testimonials" />*/}
                {/*    </Link>*/}
                {/*</ListItem>*/}
                <ListItem button onClick={props.closeMenu}>
                    <Link to={'/contact'}>
                        <ListItemText primary="Contact" />
                    </Link>
                </ListItem>





            </List>



        </Drawer>
    );
}
