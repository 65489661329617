import React from 'react';
import { Link } from 'react-router-dom';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import styled from "styled-components";
import {Button} from "react-bootstrap";


export default (props) => {

    const {className = '', price, make, model, images, mileage, transmission, year} = props.fields;
    const sys = props.sys;
    // console.log('images[0]', images[0])
    return (
        <Col xs={12} md={6} className={`pb-3 ${className}`}>
            <Row>
                <Col>
                    <Link to={`/cars/${sys.id}`}>
                        <img className={'img-fluid'} src={images[0].fields.file.url} alt='' />
                    </Link>
                </Col>
            </Row>
            <Row>
                <Col xs={4} className={'pl-3 pt-3 text-left'}>
                    <h5 className={'mont-bold'} style={{fontWeight: 700, lineHeight: '1rem'}}>
                        {make}{' '}{model}
                    </h5>
                </Col>
                <Col className={'pt-3'}>
                    <List>
                        <li>
                            <h5>Year</h5>
                            <p>{year}</p>
                        </li>
                        <li>
                            <h5>Transmission</h5>
                            <p>{transmission}</p>
                        </li>
                        <li>
                            <h5>Mileage</h5>
                            <p>{mileage}</p>
                        </li>
                    </List>
                </Col>
                <Col xs={4} className={'text-right pr-3 pt-3'}>
                    <h5 className={'mont-bold'} style={{fontWeight: 700}}>{price}</h5>
                    <Link to={`/cars/${sys.id}`}>
                        <DetailsButton>View Details</DetailsButton>
                    </Link>
                </Col>
            </Row>
        </Col>
    );
}

const DetailsButton = styled(Button)`
    background-color: #FFF;
    color: #090808;
    border-radius: 200px;
    border: 1px solid #090808;
    padding: .2rem 1rem 0;
    font-size: 0.7rem;
`;

const List = styled.ul`
  padding: 0;
  list-style: none;
  
  li {
    height: 15px !important;
    display: flex;
    justify-content: space-between;
    align-items: baseline;  
  }
  
  h5 {
    margin: 0;
    font-size: .7rem;
  }
  
  p {
    padding: 0;
    font-size: .7rem !important;
  }  
`;