import React from 'react';

import Container from 'react-bootstrap/Container';

import ProkoNavigation from "../components/nav";
import Footer from "../components/footer";
import CarListings from "../components/car-listings";
import Banner from "../components/banner";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CarsFilter from "../components/car-listings/filter";
import Car from "../components/car-listings/car";
import styled from "styled-components";
import {Button} from "react-bootstrap";

import Person from '../components/person';

export default () => {

    return (
        <>
            <ProkoNavigation showLogo />

            <div className={'mt-5'}>
                <img className={'w-100'} src={'/images/testimonials-banner.png'} alt={''} />
            </div>

            <Container fluid>


                <Row>

                    <Col className={'text-center pt-5'}>
                        <h5>Rich Colvill - Porsche 964</h5>
                    </Col>
                </Row>


                <Row>
                    <Col xs={2}></Col>
                    <Col className={'text-center pt-0 pb-5'}>
                        <img className={'w-100'} src={'/images/purple-porsch.jpg'} />
                        <p className={'mt-5'}>
                            "We have a wealth of experience in motorsport and successful engine upgrades and from scratch builds. Our technicians have experience in even the 60’s engines which not a lot of specialists have anymore. We have up to date training and experience in the newer models, so if you require a rebuild, upgrade or from scratch build we can accomodate"
                        </p>
                        <EmailButton href={"mailto:info@strasse.co.uk"}>Enquire</EmailButton>
                    </Col>
                    <Col xs={2}></Col>
                </Row>



                <Row>
                    <Col className={'text-center pt-5'}>
                        <h5>Rich Colvill - Porsche 964</h5>
                    </Col>
                </Row>


                <Row>
                    <Col xs={2}></Col>
                    <Col className={'text-center pt-0 pb-5'}>
                        <img className={'w-100'} src={'/images/purple-porsch.jpg'} />
                        <p className={'mt-5'}>
                            "We have a wealth of experience in motorsport and successful engine upgrades and from scratch builds. Our technicians have experience in even the 60’s engines which not a lot of specialists have anymore. We have up to date training and experience in the newer models, so if you require a rebuild, upgrade or from scratch build we can accomodate"
                        </p>
                        <EmailButton href={"mailto:info@strasse.co.uk"}>Enquire</EmailButton>
                    </Col>
                    <Col xs={2}></Col>
                </Row>


                <Row>
                    <Col className={'text-center pt-5'}>
                        <h5>Rich Colvill - Porsche 964</h5>
                    </Col>
                </Row>


                <Row>
                    <Col xs={2}></Col>
                    <Col className={'text-center pt-0 pb-5'}>
                        <img className={'w-100'} src={'/images/purple-porsch.jpg'} />
                        <p className={'mt-5'}>
                            "We have a wealth of experience in motorsport and successful engine upgrades and from scratch builds. Our technicians have experience in even the 60’s engines which not a lot of specialists have anymore. We have up to date training and experience in the newer models, so if you require a rebuild, upgrade or from scratch build we can accomodate"
                        </p>
                        <EmailButton href={"mailto:info@strasse.co.uk"}>Enquire</EmailButton>
                    </Col>
                    <Col xs={2}></Col>
                </Row>


            </Container>


            <Footer />

        </>
    );
}

const EmailButton = styled.a`
text-decoration: none;
    background-color: #fff;
    color: #090808;
    border-radius: 200px;
    border: 1px solid #090808;
    padding: 0 1rem;
`;
