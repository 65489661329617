import React from 'react';
import Carousel from "react-bootstrap/Carousel";
import { Parallax } from 'react-parallax';

export default () => {

    const text = [
        'Welcome to Strasse, the North’s Leading Independent Porsche Specialist.',
        'Our background in motorsport means your Porsche is in good hands.',
        'Shop our magnificent marques. All officially approved and checked by us.'
    ];

    return (
        <>
        {/*<img style={{top: 0, zIndex: 1, left: '50%', transform: 'translateX(-50%)'}} className={'position-absolute w-100'} src={'/images/logo-overlay.png'} alt={''} />*/}
        <Carousel id={'carousel'} style={{background: 'url("/images/slider-background.png")', backgroundSize: 'cover'}}>

            {[1, 2, 3].map(i => (
                <Carousel.Item>
                    {/*<Parallax bgImage="/images/slider-background.png" bgImageAlt="" strength={250}>*/}
                        <img
                            className="d-block w-100"
                            src={`/images/slide-${i}.png`}
                            alt=""
                        />
                    {/*</Parallax>*/}

                    <Carousel.Caption>
                        <h3>{text[i - 1]}</h3>
                    </Carousel.Caption>
                </Carousel.Item>
            ))}

        </Carousel>
        </>
    );
}
