import React from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { Parallax, ParallaxBanner } from 'react-scroll-parallax';


import { Link } from 'react-router-dom';


export default () => {
    const icons = [
        '/images/icon-1.png',
        '/images/icon-7.png',

        '/images/icon-2.png',

        '/images/icon-4.png',
        '/images/icon-5.png',
        '/images/icon-6.png',
        '/images/icon-3.png',
        '/images/icon-8.png'
    ];

    const links = [
      '/services',
      '',
      '',
      '/engineroom',
      '/engineroom',
      '',
      '',
      ''
    ];

    const titles = [
        'Service<br/>Prices',
        'Wheel<br/>Alignment',
        'Air Con<br/>Service',
        'Engine<br/>Rebuilds',
        'IMS Bearing <br/>Upgrade',
        'Motosport<br/>prep',
        'Vehicle<br/>Detailing',
        'Wheel<br/>Renovation'
    ];


    return (
    <div style={{background: '#000'}} className={'horizontal-icons pt-3'}>
        <Container className={'d-none d-lg-block'} fluid>
            <Row className={'pt-0'}>
                <Col className={'text-center pt-5 mb-3'}>
                    <h3 className={'grey mb-3'}>OUR SERVICES</h3>
                </Col>
            </Row>

            <Row className={'pb-5'}>
                <Col>
                    <img className='w-100' src='/images/services_icons.png' alt='' />
                </Col>
            </Row>

            {/* <Row className={'pb-5'}>
                <Col className={'d-flex'} style={{justifyContent: 'space-evenly', overflow: 'scroll'}}>
                {icons.map((i, index) => (
                    <div className={'p-0 text-center mb-5'} style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        alignItems: 'center'}}>

                        {!!(links[index] !== '') && (
                          <Link to={`${links[index]}`}>
                            <img height={'60'} className={' mt-5 mb-5'} src={i} alt={''} />
                            <p style={{fontSize: '1rem'}} className={'grey'} dangerouslySetInnerHTML={{__html: titles[index]}} />
                          </Link>
                        )}

                        {!!(links[index] === '') && (
                          <>
                              <img height={'60'} className={' mt-5 mb-5'} src={i} alt={''} />
                              <p style={{fontSize: '1rem'}} className={'grey'} dangerouslySetInnerHTML={{__html: titles[index]}} />
                          </>
                        )}

                    </div>
                ))}
                </Col>
            </Row> */}
        </Container>












        {/*<Container className={'d-block d-lg-none'}>*/}
        {/*    <Row className={'pt-3'}>*/}
        {/*        <Col className={'text-center pt-5 mb-3'}>*/}
        {/*            <h3 className={'grey'}>OUR SERVICES</h3>*/}
        {/*        </Col>*/}
        {/*    </Row>*/}

        {/*    <Row className={''}>*/}
        {/*        <Col className={''} style={{overflow: 'scroll'}}>*/}
        {/*            {[icons[0]].map((i, index) => (*/}
        {/*              <div className={'p-0 text-center'}>*/}

        {/*                  {!!(links[0] !== '') && (*/}
        {/*                    <Link to={links[0]}>*/}
        {/*                        <img height={'60'} className={' mt-5 mb-1'} src={i} alt={''} />*/}
        {/*                        <p style={{fontSize: '1rem'}} className={'grey'} dangerouslySetInnerHTML={{__html: titles[index]}} />*/}
        {/*                    </Link>*/}
        {/*                  )}*/}
        {/*                  {!!(links[0] === '') && (*/}
        {/*                    <>*/}
        {/*                        <img height={'60'} className={' mt-5 mb-1'} src={i} alt={''} />*/}
        {/*                        <p style={{fontSize: '1rem'}} className={'grey'} dangerouslySetInnerHTML={{__html: titles[index]}} />*/}
        {/*                    </>*/}
        {/*                  )}*/}

        {/*              </div>*/}
        {/*            ))}*/}
        {/*        </Col>*/}
        {/*        <Col className={''} style={{overflow: 'scroll'}}>*/}
        {/*            {[icons[1]].map((i, index) => (*/}
        {/*              <div className={'p-0 text-center'}>*/}

        {/*                  {!!(links[1] !== '') && (*/}
        {/*                    <Link to={links[0]}>*/}
        {/*                        <img height={'60'} className={' mt-5 mb-1'} src={i} alt={''} />*/}
        {/*                        <p style={{fontSize: '1rem'}} className={'grey'} dangerouslySetInnerHTML={{__html: titles[index]}} />*/}
        {/*                    </Link>*/}
        {/*                  )}*/}
        {/*                  {!!(links[1] === '') && (*/}
        {/*                    <>*/}
        {/*                        <img height={'60'} className={' mt-5 mb-1'} src={i} alt={''} />*/}
        {/*                        <p style={{fontSize: '1rem'}} className={'grey'} dangerouslySetInnerHTML={{__html: titles[index]}} />*/}
        {/*                    </>*/}
        {/*                  )}*/}
        {/*              </div>*/}
        {/*            ))}*/}
        {/*        </Col>*/}
        {/*    </Row>*/}
        {/*    <Row className={'pb-0'}>*/}
        {/*        <Col className={''} style={{overflow: 'scroll'}}>*/}
        {/*            {[icons[2]].map((i, index) => (*/}
        {/*              <div className={'p-0 text-center'}>*/}

        {/*                  {!!(links[2] !== '') && (*/}
        {/*                    <Link to={links[0]}>*/}
        {/*                        <img height={'60'} className={' mt-5 mb-1'} src={i} alt={''} />*/}
        {/*                        <p style={{fontSize: '1rem'}} className={'grey'} dangerouslySetInnerHTML={{__html: titles[index]}} />*/}
        {/*                    </Link>*/}
        {/*                  )}*/}
        {/*                  {!!(links[2] === '') && (*/}
        {/*                    <>*/}
        {/*                        <img height={'60'} className={' mt-5 mb-1'} src={i} alt={''} />*/}
        {/*                        <p style={{fontSize: '1rem'}} className={'grey'} dangerouslySetInnerHTML={{__html: titles[index]}} />*/}
        {/*                    </>*/}
        {/*                  )}*/}
        {/*              </div>*/}
        {/*            ))}*/}
        {/*        </Col>*/}
        {/*        <Col className={''} style={{overflow: 'scroll'}}>*/}
        {/*            {[icons[3]].map((i, index) => (*/}
        {/*              <div className={'p-0 text-center'}>*/}

        {/*                  {!!(links[3] !== '') && (*/}
        {/*                    <Link to={links[0]}>*/}
        {/*                        <img height={'60'} className={' mt-5 mb-1'} src={i} alt={''} />*/}
        {/*                        <p style={{fontSize: '1rem'}} className={'grey'} dangerouslySetInnerHTML={{__html: titles[index]}} />*/}
        {/*                    </Link>*/}
        {/*                  )}*/}
        {/*                  {!!(links[3] === '') && (*/}
        {/*                    <>*/}
        {/*                        <img height={'60'} className={' mt-5 mb-1'} src={i} alt={''} />*/}
        {/*                        <p style={{fontSize: '1rem'}} className={'grey'} dangerouslySetInnerHTML={{__html: titles[index]}} />*/}
        {/*                    </>*/}
        {/*                  )}*/}
        {/*              </div>*/}
        {/*            ))}*/}
        {/*        </Col>*/}
        {/*    </Row>*/}
        {/*    <Row className={'pb-0'}>*/}
        {/*        <Col className={''} style={{overflow: 'scroll'}}>*/}
        {/*        {[icons[4]].map((i, index) => (*/}
        {/*              <div className={'p-0 text-center'}>*/}

        {/*                  {!!(links[4] !== '') && (*/}
        {/*                    <Link to={links[0]}>*/}
        {/*                        <img height={'60'} className={' mt-5 mb-1'} src={i} alt={''} />*/}
        {/*                        <p style={{fontSize: '1rem'}} className={'grey'} dangerouslySetInnerHTML={{__html: titles[index]}} />*/}
        {/*                    </Link>*/}
        {/*                  )}*/}
        {/*                  {!!(links[4] === '') && (*/}
        {/*                    <>*/}
        {/*                        <img height={'60'} className={' mt-5 mb-1'} src={i} alt={''} />*/}
        {/*                        <p style={{fontSize: '1rem'}} className={'grey'} dangerouslySetInnerHTML={{__html: titles[index]}} />*/}
        {/*                    </>*/}
        {/*                  )}*/}
        {/*                  </div>*/}
        {/*            ))}*/}
        {/*        </Col>*/}
        {/*        <Col className={''} style={{overflow: 'scroll'}}>*/}
        {/*            {[icons[5]].map((i, index) => (*/}
        {/*              <div className={'p-0 text-center'}>*/}

        {/*                  {!!(links[5] !== '') && (*/}
        {/*                    <Link to={links[0]}>*/}
        {/*                        <img height={'60'} className={' mt-5 mb-1'} src={i} alt={''} />*/}
        {/*                        <p style={{fontSize: '1rem'}} className={'grey'} dangerouslySetInnerHTML={{__html: titles[index]}} />*/}
        {/*                    </Link>*/}
        {/*                  )}*/}
        {/*                  {!!(links[5] === '') && (*/}
        {/*                    <>*/}
        {/*                        <img height={'60'} className={' mt-5 mb-1'} src={i} alt={''} />*/}
        {/*                        <p style={{fontSize: '1rem'}} className={'grey'} dangerouslySetInnerHTML={{__html: titles[index]}} />*/}
        {/*                    </>*/}
        {/*                  )}*/}
        {/*              </div>*/}
        {/*            ))}*/}
        {/*        </Col>*/}
        {/*    </Row>*/}
        {/*    <Row className={'pb-0'}>*/}
        {/*        <Col className={''} style={{overflow: 'scroll'}}>*/}
        {/*            {[icons[6]].map((i, index) => (*/}
        {/*              <div className={'p-0 text-center'}>*/}

        {/*                  {!!(links[6] !== '') && (*/}
        {/*                    <Link to={links[0]}>*/}
        {/*                        <img height={'60'} className={' mt-5 mb-1'} src={i} alt={''} />*/}
        {/*                        <p style={{fontSize: '1rem'}} className={'grey'} dangerouslySetInnerHTML={{__html: titles[index]}} />*/}
        {/*                    </Link>*/}
        {/*                  )}*/}
        {/*                  {!!(links[6] === '') && (*/}
        {/*                    <>*/}
        {/*                        <img height={'60'} className={' mt-5 mb-1'} src={i} alt={''} />*/}
        {/*                        <p style={{fontSize: '1rem'}} className={'grey'} dangerouslySetInnerHTML={{__html: titles[index]}} />*/}
        {/*                    </>*/}
        {/*                  )}*/}
        {/*              </div>*/}
        {/*            ))}*/}
        {/*        </Col>*/}
        {/*        <Col className={''} style={{overflow: 'scroll'}}>*/}
        {/*            {[icons[7]].map((i, index) => (*/}
        {/*              <div className={'p-0 text-center'}>*/}

        {/*                  {!!(links[7] !== '') && (*/}
        {/*                    <Link to={links[0]}>*/}
        {/*                        <img height={'60'} className={' mt-5 mb-1'} src={i} alt={''} />*/}
        {/*                        <p style={{fontSize: '1rem'}} className={'grey'} dangerouslySetInnerHTML={{__html: titles[index]}} />*/}
        {/*                    </Link>*/}
        {/*                  )}*/}
        {/*                  {!!(links[7] === '') && (*/}
        {/*                    <>*/}
        {/*                        <img height={'60'} className={' mt-5 mb-1'} src={i} alt={''} />*/}
        {/*                        <p style={{fontSize: '1rem'}} className={'grey'} dangerouslySetInnerHTML={{__html: titles[index]}} />*/}
        {/*                    </>*/}
        {/*                  )}*/}
        {/*                  </div>*/}
        {/*            ))}*/}
        {/*        </Col>*/}
        {/*    </Row>*/}

        {/*</Container>*/}
        </div>
    );
}