import React from 'react';

import ProkoNavigation from "../components/nav";
import Footer from "../components/footer";
import PartsListings from "../components/parts-listings";

export default () => {

    return (
        <>
            <ProkoNavigation showLogo />

            <PartsListings />

            <Footer />

        </>
    );
}