import React from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { Link } from 'react-router-dom';
import { Parallax, ParallaxBanner } from 'react-scroll-parallax';

export default () => {
    return (

        <Container fluid>
            <Row>
                <Col className={'text-center mt-5 mb-5 pt-5 pb-5'}>
                    <h5 className={'mb-3'}>WE ARE DEDICATED TO HELPING OUR CUSTOMERS GET THE MOST FROM THEIR CARS.</h5>

                    <p className={'mr-auto ml-auto w-75'}>
                        We offer friendly expert advice, superb workshop facilities, the expertise of an unrivalled team of Porsche trained service technicians, fantastic parts prices, and a hand-picked selection of the best used Porsche cars for sale.
                    </p>

                    <p className={'mr-auto ml-auto w-75'}>
                        When you have finished browsing through our website, call us for a chat. We might have just what you’re looking for -and you won’t know until you’ve checked!
                    </p>

                </Col>
            </Row>
            <Row style={{background: 'black'}}>
                <Col xs={12} lg={4} className={'p-0'} style={{background: 'url(/images/about-us.png)'}}>
                    <Link to={'/cars'} style={{color: 'white'}}>
                      <img className={'rounded-0 w-100'} src="/images/about-us.png" alt="Card image" />
                      <h5 style={{position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)'}}>CARS FOR SALE</h5>
                    </Link>
                </Col>
                <Col xs={12} lg={4} className={'p-0'}>

                    <Link to={'/about'} style={{color: 'white'}}>
                        <img className={'rounded-0 w-100'} src="/images/Strasse-web-s3.png" alt="Card image" />
                        <h5 style={{position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)'}}>ABOUT STRASSE</h5>
                    </Link>
                </Col>
                <Col xs={12} lg={4} className={'p-0'}>
                    <Link to={'/motorsport'} style={{color: 'white'}}>
                        <img className={'rounded-0 w-100'} src="/images/motorsport.png" alt="Card image" />
                        <h5 style={{position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)'}}>MOTORSPORT</h5>
                    </Link>
                </Col>
            </Row>

        </Container>
    );
}