import React from 'react';

import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styled from "styled-components";
import {Button} from "react-bootstrap";

import ProkoNavigation from "../components/nav";
import Footer from "../components/footer";

export default () => {
    return (
        <>
            <ProkoNavigation showLogo />
            <div className={'mt-5'}>
                <img className={'w-100'} src={'/images/contact-2-banner.png'} alt={''} />
            </div>

            <Container fluid>
                <Row>
                    {/*<Col className={'pt-5 text-center'} style={{background: '#FFF', display: 'flex', alignItems: 'center'}}>*/}

                    {/*    <Form className={'pl-5 pr-5'}>*/}
                    {/*        <h5>Message us</h5>*/}
                    {/*        <Form.Group>*/}
                    {/*            <Form.Control*/}
                    {/*                className={'rounded-0 border-top-0 border-left-0 border-right-0 border-bottom'}*/}
                    {/*                type="text"*/}
                    {/*                placeholder="Name"*/}
                    {/*            />*/}
                    {/*            <Form.Control*/}
                    {/*                className={'rounded-0 border-top-0 border-left-0 border-right-0 border-bottom'}*/}
                    {/*                type="text"*/}
                    {/*                placeholder="Telephone"*/}
                    {/*            />*/}
                    {/*            <Form.Control*/}
                    {/*                className={'rounded-0 border-top-0 border-left-0 border-right-0 border-bottom'}*/}
                    {/*                type="email"*/}
                    {/*                placeholder="Email"*/}
                    {/*            />*/}
                    {/*            <Form.Control*/}
                    {/*                className={'rounded-0 border-top-0 border-left-0 border-right-0 border-bottom'}*/}
                    {/*                type="text"*/}
                    {/*                placeholder="Subject"*/}
                    {/*            />*/}
                    {/*            <Form.Control*/}
                    {/*                resize={false}*/}
                    {/*                as="textarea" rows={4}*/}
                    {/*                className={'rounded-0 border-top-0 border-left-0 border-right-0 border-bottom'}*/}
                    {/*                type="text"*/}
                    {/*                placeholder="Message"*/}
                    {/*            />*/}

                    {/*            <Form.Check*/}
                    {/*                type={'checkbox'}*/}
                    {/*                label={`I consent for my details to be used for marketing purposes`}*/}
                    {/*            />*/}
                    {/*        </Form.Group>*/}

                    {/*        <RedButton>Submit</RedButton>*/}



                    {/*    </Form>*/}
                    {/*</Col>*/}
                    <Col className={'p-0'} style={{background: '#FFF'}}>
                        <div className="map-responsive">
                            <iframe
                              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2357.024090401783!2d-1.5611729838793578!3d53.78905878007422!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48795e99189f21f5%3A0xbb5257504b945a48!2sStrasse%20(UK)!5e0!3m2!1sen!2suk!4v1624379775759!5m2!1sen!2suk"
                              width="800" height="600" style={{border:0}} allowFullScreen="" loading="lazy"></iframe>
                        </div>
                    </Col>
                </Row>
            </Container>

            <div className={'d-none d-lg-block text-center pt-5 pb-5'} style={{background: '#EB212D'}}>
                <h2 className={'w-100 mt-5 text-white text-center'} style={{color: '#000'}}>Follow us on social for up to date news, events,<br/>motorsport and... social.</h2>
                <div className={'mt-5'} style={{margin: '0 auto'}}>
                    <a className={'hover-border mont text-white mr-3'} style={{borderRadius: '200px', display: 'inline-block', padding: '0px 10px'}} href={'https://www.facebook.com/StrassePorsche/'} target={'_blank'}>Facebook</a>
                    <a className={'hover-border mont text-white mr-3'} style={{borderRadius: '200px', display: 'inline-block', padding: '0px 10px'}} href={'https://www.instagram.com/strasseporsche/'} target={'_blank'}>Instagram</a>
                    <a className={'hover-border mont text-white mr-3'} style={{borderRadius: '200px', display: 'inline-block', padding: '0px 10px'}} href={'https://twitter.com/strasseleeds'} target={'_blank'}>Twitter</a>
                    <a className={'hover-border mont text-white'} style={{borderRadius: '200px', display: 'inline-block', padding: '0px 10px'}} href={'https://www.youtube.com/channel/UCUtHLVFU3MQGSInpC4z0X8w'} target={'_blank'}>Youtube</a>
                </div>
            </div>

            <Footer alternate />
        </>
    );
}

const RedButton = styled(Button)`
    background-color: #fff;
    color: #EB212D;
    border-radius: 200px;
    border: 1px solid #EB212D;
    padding: 0 1rem;
    float: left;
`;