import React from 'react';
import { Link } from 'react-router-dom';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import styled from "styled-components";
import {Button} from "react-bootstrap";


export default ({className = ''}) => {

    return (
        <Col xs={6} className={`pb-3 ${className}`}>
            <Row>
                <Col>
                    <img className={'img-fluid'} src={'/images/part-listings-example.png'} alt='' />
                </Col>
            </Row>
            <Row>
                <Col xs={4} className={'pl-3 pt-3 text-left'}>
                    <h5 style={{fontWeight: 700}}>
                        PORSCHE 912 BADGE
                    </h5>
                </Col>
                <Col className={'pt-3'}>
                    <List>
                        <li>
                            <h5>Year</h5>
                            <p>1968</p>
                        </li>
                        <li>
                            <h5>Info</h5>
                            <p>Alloy grille badge</p>
                        </li>
                    </List>
                </Col>
                <Col xs={4} className={'text-right pr-3 pt-3'}>
                    <h5 style={{fontWeight: 700}}>£29.00</h5>
                    {/*<Link to={'/cars/porsche'}>*/}
                        <DetailsButton href={"mailto:info@strasse.co.uk?subject=Enquire about the Porsche 911 Turbo&body=Hi, Im interested in the Porsche you have for sale."}>Buy it now</DetailsButton>
                    {/*</Link>*/}
                </Col>
            </Row>
        </Col>
    );
}

const DetailsButton = styled.a`
text-decoration: none;
    background-color: #FFF;
    color: #090808;
    border-radius: 200px;
    border: 1px solid #090808;
    padding: .2rem 1rem 0;
    font-size: 0.7rem;
    
            display: inline-block;
        text-decoration: none !important;
        cursor: pointer;
        
        &:hover{
        color: #090808;
        }
`;

const List = styled.ul`
  padding: 0;
  list-style: none;
  
  li {
    height: 15px !important;
    display: flex;
    justify-content: space-between;
    align-items: baseline;  
  }
  
  h5 {
    margin: 0;
    font-size: .7rem;
  }
  
  p {
    padding: 0;
    font-size: .7rem !important;
  }  
`;