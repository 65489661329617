import React from 'react';
import ProkoNavigation from "../components/nav";
import ProkoCarousel from "../components/carousel";
import ProkoMobileCarousel from "../components/carousel-mobile";
import ProkoHorizontalParalax from "../components/horizontal-paralax";
import ProkoImageLinks from "../components/image-links";
import ProkoTextCarousel from "../components/text-carousel";
import ProkoHorizontalIconList from "../components/horizontal-icon-list";
import Footer from "../components/footer";

export default () => {

    return (
        <>
            <ProkoNavigation />

            <div className={'d-none d-lg-block'}>
                <ProkoCarousel />
            </div>

            <div className={'d-block d-lg-none'}>
                <ProkoMobileCarousel />
            </div>

            <ProkoHorizontalParalax />
            <ProkoImageLinks />
            {/*<ProkoTextCarousel />*/}
            <ProkoHorizontalIconList />


            <div className={'d-none d-lg-block text-center'} style={{position: 'relative'}}>
                <h2 className={'w-100 text-white text-center'} style={{position: 'absolute', bottom: '6rem'}}>Follow us on social for up to date news, events,<br/>motorsport and... social.</h2>
                <div style={{left: '50%', transform: 'translateX(-50%)', position: 'absolute', bottom: '3rem', margin: '0 auto'}}>
                    <a className={'hover-border mont text-white mr-3'} style={{borderRadius: '200px', display: 'inline-block', padding: '0px 10px'}} href={'https://www.facebook.com/StrassePorsche/'} target={'_blank'}>Facebook</a>
                    <a className={'hover-border mont text-white mr-3'} style={{borderRadius: '200px', display: 'inline-block', padding: '0px 10px'}} href={'https://www.instagram.com/strasseporsche/'} target={'_blank'}>Instagram</a>
                    <a className={'hover-border mont text-white mr-3'} style={{borderRadius: '200px', display: 'inline-block', padding: '0px 10px'}} href={'https://twitter.com/strasseleeds'} target={'_blank'}>Twitter</a>
                    <a className={'hover-border mont text-white'} style={{borderRadius: '200px', display: 'inline-block', padding: '0px 10px'}} href={'https://www.youtube.com/channel/UCUtHLVFU3MQGSInpC4z0X8w'} target={'_blank'}>Youtube</a>
                </div>
                <img className={'w-100'} src={'/images/footer-banner.png'} alt={''} />
            </div>

            <Footer />

        </>
    );
}