import React from 'react';
import { Link } from 'react-router-dom';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

import Car from './car';
import CarsFilter from './filter';

import styled from "styled-components";
import { Button } from "react-bootstrap";

export default () => {



    return (

        <Container fluid>
            {/*<CarsFilter />*/}
            <Row className={'pt-4 mt-5'}>
                <Col className={'pt-3'} style={{background: '#FFF'}}>
                    <Row className={'pt-0'}>
                        <Car className={''} />
                        <Car className={''} />
                        <Car className={''} />
                        <Car className={''} />
                        <Car className={''} />
                        <Car className={''} />
                    </Row>

                </Col>
                <Col style={{background: '#E2E0DF'}} xs={3} className={'d-none d-lg-block pb-5'}>
                    <p className={'mt-4'}>Parts for your Porsche<br/><br/>
                        We offer next day mail-order delivery on most
                        items - all are in excellent condition - and
                        we try to supply them at the lowest possible
                        price.
                        If you need any advice on our parts please...</p>


                    <h5 className={'pt-3'} style={{fontWeight: 700}}>
                        call us about a part<br />
                        0113 2340911
                    </h5>

                    {/*<Link to={'/cars/porsche'}>*/}
                        <EmailButton href={"mailto:info@strasse.co.uk?subject=Enquire about the Porsche 911 Turbo&body=Hi, Im interested in the Porsche you have for sale."} className={'mt-5'}>Email us</EmailButton>
                    {/*</Link>*/}
                </Col>
            </Row>
        </Container>
    );
}



const EmailButton = styled.a`
text-decoration: none;
    background-color: #E2E0DF;
    color: #090808;
    border-radius: 200px;
    border: 1px solid #090808;
    padding: 0 1rem;
    
            display: inline-block;
        text-decoration: none !important;
        cursor: pointer;
        
        &:hover{
        color: #090808;
        }
\`;
`;