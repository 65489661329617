import React, { useState } from 'react';
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import styled from "styled-components";
import {Button} from "react-bootstrap";

import { Link } from 'react-router-dom';
import Drawer from "../components/draw";

export default ({showLogo}) => {
    const [open, setOpen] = useState(false);
    const closeMenu = () => setOpen(false);

    return (
        <>
        <Bar collapseOnSelect fixed="top" className='pt-4 pb-4' expand="never" >
            <Navbar.Brand href="/">
                <img id={`${showLogo ? 'persistent-logo' : 'logo'}`} width="200" alt={""} src={'/images/text-logo.png'} />
            </Navbar.Brand>

            <img onClick={() => setOpen(true)} style={{cursor: 'pointer'}} width={'40'} src={'images/menu.png'} alt={''} style={{marginLeft: '-80px'}}  />

            {/*<Nav className="">*/}
            {/*    <Nav.Item>*/}
            {/*        <Link to={'/cars'}>*/}
            {/*            <RedButton>Shop Strasse</RedButton>*/}
            {/*        </Link>*/}
            {/*    </Nav.Item>*/}
            {/*</Nav>*/}
        </Bar>
        <Drawer open={open} closeMenu={closeMenu} />
    </>

    );
}


const Bar = styled(Navbar)`
    background-color: #080605;
`;

const RedButton = styled(Button)`
    background-color: #080605;
    color: #EB212D;
    border-radius: 200px;
    border: 1px solid #EB212D;
    padding: .15rem 1rem 0;
`;